form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.body > h2 {
    margin-left: 2rem;
    margin-bottom: 0rem;
}

.body > p {
    margin-left: 2rem;
    margin-bottom: 0.5rem;
}

input {
    width: 95%;
    padding: 1.4rem;
    border-radius: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    background: transparent;
    border: 2px solid var(--color-text);
    resize: none;
    color: var(--color-text);
}

.image_box {
    background: wheat;
    padding: 2rem;
    border-radius: 0.6rem;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    border: 4px solid black;
    text-align: center;
}

.image_box > p {
    color: black;
    font-size: 1rem;
}

.joke_container {
    margin-top: 1.2rem;
    margin-left: 1rem;
    flex-wrap: wrap;
}

.unsplash_image {
    max-width: 30%;
    border: 5px solid black;
  }