@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bkg: rgb(51, 51, 51);
    --color-text: white;
    --color-btn: #f64040;
    --color-text2: green;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Abril Fatface', cursive;
    background: var(--color-bkg);
    color: var(--color-text);
    line-height: 1.7;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 4rem;
}

.btn {
    width: max-content;
    display: inline-block;
    padding: 0.75rem 1rem;
    border-radius: 0.4rem;
    margin-left: 2rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    background: var(--color-btn);
    color: var(--color-text);
    font-family: 'Abril Fatface', cursive;
}